@import 'styles/index.scss';

.BasePage {
    // &--ProjectPage {
    //     background-color: #fff;
    // }
}

.SkipLink {
    display: none;

    @include media(m) {
        display: block;
    }

    top: -2000px;
    left: -2000px;

    opacity: 0;
    position: absolute;
    background-color: $colorBlue;
    color: #fff;
    padding: 15px 20px;

    &:focus {
        opacity: 1;
        top: 10px;
        left: 10px;
    }
}
