@import 'styles/includes.scss';

.Navigation {
    $root: &;

    background-color: #fff;
    padding: 5rem 0 0rem 0;

    &__Container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:after {
            content: '';
            box-shadow: 0 7px 16px 0 rgba(33, 33, 33, 0.05);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    &__MenuWrap {
        @extend %container-large;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__Header {
        margin-bottom: auto;
    }

    &__Logotype {
        display: block;
        width: 100%;
        height: 47px;
        min-width: 127px;

        img {
            width: 100%;
            height: 47px;
            min-width: 127px;
        }
    }

    &__Menu {
        justify-content: space-between;
        display: flex;
        gap: 6.4rem;
    }

    &__TopMenu {
        width: 100%;
        display: grid;
        place-items: center;
    }

    &__TopMenuInner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 2px;
    }

    &__Search {
    }

    &__MyPage {
        align-self: center;
        button {
            font-weight: normal;
        }
    }

    &__ToggleItem {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 14px;
        gap: 0.3rem;
        transition: background-color $transition;

        &:hover {
            background-color: #f0efee;
        }

        &--Expanded {
            background-color: #f0efee;
        }

        &--Active {
            background-color: #f0efee;
        }

        &:focus {
            outline: 2px solid $colorBlue;
            outline-offset: 2px;
        }
    }

    &__ToggleItemTitle {
        @extend %nav-titles;
        font-family: $fontRaleway;
        font-weight: 700;
        letter-spacing: 0.4px;
        color: $colorBlack;
        text-decoration: none;
        font-size: 1.6rem !important;
    }

    &__ToggleItemIcon {
        width: 24px;
        height: 24px;

        .Navigation__ToggleItem--Expanded & {
            transform: rotate(180deg);
            width: 24px;
            height: 24px;
        }
    }

    &__ToggleItemWrapper {
        position: relative;

        &:last-child {
            margin-right: 1.6rem;
        }
    }

    &__MegaMenu {
        z-index: 100;
        position: absolute;
        top: 5.8rem;
        right: -6rem;
        min-width: 40rem;
        background-color: #fff;
        filter: drop-shadow(0px 4px 14px rgba(103, 92, 83, 0.1));

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #4d7aa1;
        }
    }

    &__MegaMenuContainer {
        padding: 5rem;
    }

    &__MegaMenuMainLink {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        text-decoration: none;
        margin-bottom: 3rem;

        &:hover {
            text-decoration: none;
        }
    }

    &__MegaMenuMainLinkTitle {
        font-family: $fontRaleway;
        color: $colorBlack;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.4px;
    }

    &__MegaMenuMainLinkIcon {
        transition: transform $transition;

        #{$root}__MegaMenuMainLink:hover & {
            transform: translateX(5px);
        }
    }

    &__MegaMenuLinks {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__MegaMenuItemLink {
        font-size: 1.6rem;
        white-space: nowrap;
        color: $colorBlack;
        line-height: 30px;
        position: relative;
        width: fit-content;

        &::after {
            content: '';
            display: inline-block;
            width: 6px;
            height: 9px;
            background-image: url(/assets/svg/arrow--right-black.svg);
            background-repeat: no-repeat;
            background-position: 100%;
            background-size: contain;
            font-weight: 400;
            vertical-align: middle;
            margin-left: 8px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__Buttons {
        display: flex;
    }
}
