@import 'styles/includes.scss';

.Footer {
    width: 100%;
    position: relative;
    background: $colorAshGrey;
    z-index: 90;
    padding-top: 4.5rem;
    padding-bottom: 3.2rem;
    margin-top: 6rem;

    @include media(m) {
        padding-top: 9rem;
        padding-bottom: 6.4rem;
        margin-top: 10rem;
    }

    &__Menu {
        @extend %container;
        z-index: 999;
        position: relative;
    }

    &__MenuInner {
        font-size: 16px;
        display: flex;
        flex-flow: column;
        z-index: 99;
        position: relative;
    }

    &__MenuBottom {
        position: relative;
        width: 100%;
        padding-top: 2rem;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.8rem;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: white;
        }
    }

    &__LogoContainer {
        display: flex;
        justify-content: start;

        @include media(m) {
            position: absolute;
            top: -5.6rem;
            left: 0;
        }

        @include media(l) {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            justify-content: flex-start;
        }

        img {
            height: 4.7rem;
        }

        a {
            height: fit-content;
        }
    }

    &__MenuSection {
        display: grid;
        width: 100%;
        padding-bottom: 6.2rem;
        grid-template-columns: repeat(1, 1fr);
        gap: 3.2rem;

        @include media(m) {
            grid-template-columns: repeat(3, 1fr);
            padding-top: 6.2rem;
        }

        @include media(l) {
            grid-template-columns: repeat(4, 1fr);
            padding-top: 0;
        }
    }

    &__ContactNav {
        p {
            padding-bottom: 0;
            line-height: 23px;
            color: white;
        }

        h4 {
            color: white;
        }

        a {
            text-decoration: none;
            color: white;
            line-height: 30px;
            position: relative;
            width: fit-content;
            overflow: hidden;

            &:not([href^='mailto:']):not([href^='tel:'])::after {
                content: '';
                display: inline-block;
                width: 6px;
                height: 9px;
                background-image: url(/assets/svg/arrow--right-black.svg);
                background-repeat: no-repeat;
                background-position: 100%;
                background-size: contain;
                font-weight: 400;
                vertical-align: middle;
                margin-left: 8px;
                filter: invert(1);
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__ContactTitle {
        @extend %h3;
        color: white;
        padding-bottom: 16px;
        font-size: 18px;
    }

    &__AddressNav {
        p {
            line-height: 20px;
            font-size: 14px;
            color: white;
        }

        a {
            line-height: 20px;
            font-size: 14px;
            color: white;
        }
    }

    &__InfoNav {
        display: flex;
        flex-direction: column;

        a {
            text-decoration: none;
            color: white;
            line-height: 30px;
            position: relative;
            width: fit-content;
            overflow: hidden;

            &:not([href^='mailto:']):not([href^='tel:'])::after {
                content: '';
                display: inline-block;
                width: 6px;
                height: 9px;
                background-image: url(/assets/svg/arrow--right-black.svg);
                background-repeat: no-repeat;
                background-position: 100%;
                background-size: contain;
                font-weight: 400;
                vertical-align: middle;
                margin-left: 8px;
                filter: invert(1);
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__SocialNav {
        display: flex;
        flex-direction: column;

        &__Links {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            align-items: center;
        }

        &__Link {
            width: 32px;
            height: 32px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__Link--Facebook {
            background-image: url('#{$assetPath}svg/icon--facebook.svg');
        }
        &__Link--Linkedin {
            background-image: url('#{$assetPath}svg/icon--linkedin.svg');
        }
        &__Link--Instagram {
            background-image: url('#{$assetPath}svg/icon--instagram.svg');
        }
    }

    &__Copyright {
        @extend %body-copy;
        color: white;
        font-size: 14px !important;
        padding-right: 0.8rem;
    }

    &__OfficeNav {
        color: white;
    }

    &__InfoLinks {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.6rem;
    }

    &__InfoLink {
        @extend %body-copy;
        color: white;
        font-size: 14px !important;
        text-decoration: none;
        color: white;
        position: relative;
        width: fit-content;
        overflow: hidden;

        &:not([href^='mailto:']):not([href^='tel:'])::after {
            content: '';
            display: inline-block;
            width: 6px;
            height: 9px;
            background-image: url(/assets/svg/arrow--right-black.svg);
            background-repeat: no-repeat;
            background-position: 100%;
            background-size: contain;
            font-weight: 400;
            vertical-align: middle;
            margin-left: 8px;
            filter: invert(1);
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
