@import 'styles/includes.scss';

.SearchModal {
    $root: &;
    height: 52px;
    width: 100%;
    background-color: #c2beba;

    @include media(m) {
        height: 64px;
    }

    &__Container {
        @extend %container;
        position: relative;
        padding-top: 22px;
        padding-bottom: 28px;
    }

    &__Search {
        input {
            @extend %button-titles-small;
            padding: 16px;
            font-size: 1.6rem;
            color: $colorBlack;
            line-height: 0.78;
            letter-spacing: 0.4px;
            background-color: #c2beba;
            border: none;
            height: 52px;
            position: absolute;
            top: 0;
            left: 0;
            width: 75%;

            @include media(m) {
                background-color: #c2beba;
                color: $colorBlack;
                height: 64px;
            }

            @include media(m) {
                padding: 0;
                padding-left: 20px;
                margin-left: -20px;
                width: calc(85% + 20px);
            }

            &::placeholder {
                color: $colorBlack;

                @include media(m) {
                    color: $colorBlack;
                }
            }
        }
    }

    &__SearchButton {
        display: none;

        @include media(m) {
            display: block;
        }
    }

    &--NotFoundPage {
        width: 100%;
        max-width: 100%;
        height: 50px;
        background-color: #ffffff;

        input {
            @extend %h3;
            font-weight: normal;
            padding-left: 16px;
            width: 100%;
            height: 50px;
            border: solid 1px $colorBlack300;
            background-color: #ffffff;
            color: $colorBlack;
            padding-top: 0;
            padding-bottom: 0;

            &::placeholder {
                color: $colorBlack;
            }
        }

        #{$root}__SearchButton {
            display: block;

            button {
                width: 50px;
                height: 50px;
                background-color: #c2beba;
                right: 0;
            }
        }
    }

    &--SearchPage {
        width: 100%;
        max-width: 100%;
        height: 50px;
        background-color: #ffffff;

        input {
            @extend %h3;
            font-weight: normal;
            padding-left: 16px;
            width: 100%;
            height: 50px;
            border: solid 1px $colorBlack300;
            background-color: #ffffff;
            color: $colorBlack;
            padding-top: 0;
            padding-bottom: 0;
            margin-left: 0;

            &::placeholder {
                color: $colorBlack;
            }
        }

        #{$root}__SearchButton {
            display: block;

            button {
                width: 50px;
                height: 50px;
                background-color: #c2beba;
                right: 0;
            }
        }
    }
}
