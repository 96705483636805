@import 'styles/includes.scss';

.Snackbar {
    background-color: #dde6ee;
    width: 100%;
    padding: 35px;

    &__Wrap {
        @extend %container;
        // justify-content: space-between;
        align-items: center;
        display: flex;
        flex-direction: column;

        @include media(m) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__Text {
        text-align: center;

        @include media(m) {
            text-align: left;
        }
    }

    &__Button {
        @extend %button;
        background-color: #fff;
        margin-top: 15px;

        @include media(m) {
            margin-top: 0;
            margin-left: 30px;
        }
    }
}
