@use 'sass:math';
@import 'styles/includes.scss';


.Wysiwyg {
    $root: &;
    @extend %container-text;

    &--ArticlePage {
        @extend %container-text;
    }

    &--AreaListPage {
        margin-left: auto;
        margin-right: auto;
        padding: 0 16px;
    
        @include media(s) {
            max-width: (map-get($maxwidths, s));
            padding: 0;
        }
    
        @include media(m) {
            max-width: (map-get($maxwidths, m));
            padding: 0;
        }
    
        @include media(l) {
            max-width: (map-get($maxwidths, l));
            padding: 0;
        }
    
        @include media(xl) {
            max-width: (map-get($maxwidths, xl));
            padding: 0;
        }
    }

    margin-bottom: 30px;
    margin-top: 30px;

    &--NotFoundPage {
        padding: 0;
    }

    &--InfoBlock {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }

    &--VideoCard {
        margin: 0;
        overflow: auto;
        padding: 0;

        @include media(m) {
            max-height: 390px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    h1 {
        @extend %h1;
    }

    h2 {
        @extend %h2;
        margin-bottom: 10px;
        + ul,
        + ol {
            margin-top: 8px;
            margin-bottom: 25px;
        }
    }

    h3 {
        @extend %h3;
        margin-bottom: 10px;
        + ul,
        + ol {
            margin-top: 8px;
            margin-bottom: 25px;
        }
    }

    h4 {
        @extend %h4;
        margin-bottom: 8px;
        + ul,
        + ol {
            margin-top: 8px;
            margin-bottom: 25px;
        }
    }

    h5 {
        @extend %h5;
        margin-bottom: 8px;
        + ul,
        + ol {
            margin-top: 8px;
            margin-bottom: 25px;
        }
    }

    a {
        color: $colorBlack;
        text-decoration: none;
        position: relative;
        width: fit-content;
        overflow: hidden;
        line-height: 30px;

        &:hover {
            text-decoration: underline;
        }

        &[href*='signalisten.se'],
        &[href^='/'],
        &[href^='..'] {
            &::after {
                content: '';
                display: inline-block;
                width: 6px;
                height: 9px;
                background-image: url(/assets/svg/arrow--right-black.svg);
                background-repeat: no-repeat;
                background-position: 100%;
                background-size: contain;
                font-weight: 400;
                vertical-align: middle;
                margin-left: 8px;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        &[href^='mailto'] {
            &:after {
                display: none;
            }
        }
        /* &:hover { */
        /*     border-bottom: 1px solid $colorBlue; */
        /* } */
    }

    a[href$='.pdf'],
    a[href$='.docx'],
    a[href$='.ppt'],
    a[href$='.pptx'],
    a[href$='.doc'] {
        padding-left: 20px;
        background-image: url('#{$assetPath}svg/icon--download.svg');
        background-repeat: no-repeat;
        background-position: 0 center;
        font-weight: normal;

        &::after {
            display: none;
        }
    }

    a[href*="//"]:not([href*="signalisten.se"])
    {
        padding-left: 32px;
        background-image: url('#{$assetPath}svg/link--external.svg');
        background-repeat: no-repeat;
        background-position: 0 center;
        font-weight: normal;
        padding-top: 0.6rem;
    }

    p {
        @extend %body-copy;
        margin-bottom: 20px;
    }

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }

    ol,
    ul {
        @extend %body-copy;
        margin-bottom: 20px;
        li {
            position: relative;
            margin: 8px 0;
        }
    }

    ul {
        @media print {
            list-style: initial !important;
            padding-left: 20px !important;
        }

        li {
            padding-left: 15px;

            &::before {
                content: '';
                width: 6px;
                height: 6px;
                margin: 0 10px 0 0;
                display: inline-block;
                position: absolute;
                top: 11.5px;
                left: 0;
                border-radius: 50%;
                background: $colorBlack;
            }

            @media print {
                padding-left: 0 !important;
            }
        }
    }

    ol {
        list-style-type: decimal;
        margin-left: 17px;

        li {
            padding-left: 5px;
        }
    }

    &--CookieBar {
        @extend %body-copy-small;
        @extend %container;
        color: #fff;
        padding: 0;
        padding-top: 30px;
        margin: 0;

        @include media(s) {
            padding-top: 0;
            padding-right: 60px;
            padding-left: 60px;
        }

        a {
            color: #fff;
        }
    }

    &--Footer {
        margin: 0;
        padding: 0;
        color: #fff;

        p {
            font-size: 1.6rem;
            line-height: 23px;
            padding: 0;
            margin: 0;

            // Add padding-top if <p> contains a <b> tag
            &:has(b) {
                padding-top: 1.6rem;
            }
        }
    }
}
