@import 'styles/includes.scss';

.Button {
    $root: &;

    @extend %button;
    position: relative;

    &__Text {
        position: relative;
        display: grid;
        place-items: center;
    }

    &--Primary {
        @extend %button-small;
        @extend %button-titles-small;
        min-height: 5.2rem !important;
        background-color: transparent;
        border: 2px solid #212121;
        border-radius: 0;
        color: #212121;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;

        @include media(m) {
            font-size: 1.6rem;
            letter-spacing: 0.4px;
            padding: 1.6rem;
        }

        &:hover {
            text-decoration: none;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &--WithArrow {
        &:hover {
            padding-right: 3.8rem !important;
        }
    }

    &--Arrow {
        padding-left: 1rem !important;
        position: absolute;
        right: -2.8rem;
        opacity: 0;
        transition: all $transition;

        #{$root}:hover & {
            opacity: 1;
        }
    }

    &--PrimaryInverted {
        @extend %button-primary-inverted;
    }

    &--PrimaryBorder {
        @extend %button-primary-border;
    }

    &--Secondary {
        @extend %button-secondary;
    }

    &--Tertiary {
        @extend %button-tertiary;
    }

    &--Quarternary {
        @extend %button-quarternary;
        border: none;
    }

    &--Small {
        @extend %button-small;
    }

    &--Disabled {
        @extend %button-disabled;
    }

    &--Icon {
        padding: 1.2rem 1.6rem;
        display: inline-flex;
        align-items: center;
        min-width: max-content;
    }

    &--CardObject {
        margin-top: 18px;
    }

    &--CookieBar {
        background: none;
        border: 0;
        width: 20px;
        height: 20px;
        padding: 10px;
        position: absolute;
        top: 15px;
        right: 10px;
        padding-top: 20px;
        padding-bottom: 20px;

        @include media(s) {
            padding-left: 30px;
            padding-right: 30px;
            right: 0;
            margin-top: auto;
            margin-bottom: auto;
            top: 0;
            bottom: 0;
            height: 100%;
        }

        @include media(m) {
            padding-left: 60px;
            padding-right: 60px;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    &--MyPageMobile {
        color: $colorBlack;
        background-color: $colorAshGrey20;
        font-size: 1.2rem;
        min-height: 6rem !important;
        height: 7.6rem;
        width: 7.6rem;
        max-width: 7.6rem;
        max-height: 7.6rem;
        border: none !important;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        gap: 0.8rem;
        padding: 0.4rem;
        padding-bottom: 0.8rem;

        &:hover {
            background-color: $colorAshGrey40;
        }

        span {
            line-height: 10px;
            font-weight: 700;
        }

        img {
            margin: 0;
            width: 32px !important;
            height: 32px !important;
        }
    }

    &--NavigationMobile {
        width: 42px;
        height: 30px;
        background-image: url('#{$assetPath}svg/hamburger-menu.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-color: unset;
        border: none;
        padding: 0;
        filter: invert(1);
    }

    &--VideoModule {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        border-radius: 50%;
        background-color: #fff;
        width: 56px;
        height: 56px;
        z-index: 99;
        border: 0;

        &:hover {
            transform: scale(1.2);
        }

        &:before {
            content: '';
            display: block;
            margin: 0 auto;
            background: #fff;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid $colorBlack600;
        }
    }

    &--Contact {
        font-size: 1.8rem;
        color: $colorBlue;
        margin-top: 48px;
        text-decoration: none;
        display: block;
        text-align: center;
        padding-bottom: 12px;
        line-height: 28px;
    }

    &--SearchModalSearch,
    &--SearchModalClose {
        background: none;
        border: 0;
        height: 52px;
        width: 52px;
        padding: 0;
        position: absolute;
        right: 6px;
        top: 0;

        @include media(m) {
            right: -30px;
            height: 64px;
            width: 64px;
        }

        img {
            margin: 0 auto;
        }
    }

    &--SearchModalSearch {
        right: 50px;

        img {
            width: 25px;
            height: 25px;
        }
    }

    &--MyPages {
        color: $colorBlack;
        background-color: $colorAshGrey20;
        font-size: 1rem;
        min-height: 6rem !important;
        height: 6.4rem;
        width: 6.4rem;
        max-width: 6.4rem;
        max-height: 6.4rem;
        border: none !important;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        gap: 0.8rem;
        padding: 0.4rem;
        padding-bottom: 0.8rem;

        &:hover {
            background-color: $colorAshGrey40;
        }

        span {
            line-height: 10px;
            font-weight: 700;
        }

        img {
            margin: 0;
            width: 30px !important;
            height: 30px !important;
        }
    }

    &--SearchButton {
        color: $colorBlack;
        background-color: $colorAshGrey60;
        font-size: 1rem;
        min-height: 6rem !important;
        height: 6.4rem;
        width: 6.4rem;
        max-width: 6.4rem;
        max-height: 6.4rem;
        border: none !important;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        gap: 0.8rem;
        padding: 0.4rem;
        padding-bottom: 0.8rem;

        &:hover {
            background-color: $colorAshGrey80;
        }

        span {
            line-height: 10px;
            font-weight: 700;
        }

        img {
            margin: 0;
            width: 30px !important;
            height: 30px !important;
        }
    }

    &--SearchButtonMobile {
        color: $colorBlack;
        background-color: $colorAshGrey40;
        font-size: 1.2rem;
        min-height: 6rem !important;
        height: 7.6rem;
        width: 7.6rem;
        max-width: 7.6rem;
        max-height: 7.6rem;
        border: none !important;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        gap: 0.8rem;
        padding: 0.4rem;
        padding-bottom: 0.8rem;

        &:hover {
            background-color: $colorAshGrey60;
        }

        span {
            line-height: 10px;
            font-weight: 700;
        }

        img {
            margin: 0;
            width: 32px !important;
            height: 32px !important;
        }
    }

    &__Icon {
        width: 18px;
        height: 21px;
        margin-left: 8px;
    }

    &--text {
        background-color: unset;

        left: 0;
        bottom: -63px;

        &:before {
            content: '';
            display: inline-block;
            width: 7px;
            height: 12px;
            background-image: url('#{$assetPath}svg/arrow--right-blue.svg');
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 16px;
            transform: rotate(180deg);
        }
    }

    &--AlignCenter {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--Rotate {
        display: flex;
        justify-content: center;
        align-items: center;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            display: inline-block;
            width: 7px;
            height: 12px;
            background-image: url('#{$assetPath}svg/arrow--right-blue.svg');
            background-size: contain;
            background-repeat: no-repeat;
            vertical-align: middle;
            margin-left: 16px;
        }

        @include media(s) {
            right: 128px;
        }
    }
}
